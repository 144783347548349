import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from '../app/configs';
import {
  getAccessToken, regenerateToken, verifyTokenExpired,
} from './auth/auth.util';

const rtkFetchWrapper = () => fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: async (headers) => {
    let token: string | null;
    const bufferLoginTime = 3 * 60; // in seconds
    const isTokenExpired = verifyTokenExpired(bufferLoginTime);
    if (isTokenExpired) {
      token = await regenerateToken();
    } else {
      token = getAccessToken();
    }

    if (!token) window.location.href = '/';

    headers.set('Authorization', `Bearer ${token}`);
    return headers;
  },
});

export default rtkFetchWrapper;
