import { createApi } from '@reduxjs/toolkit/query/react';
import configs from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import {
  CompanyState, DesignCodeState, EquipmentState, GradeState, MaterialState, MaterialTypeState,
} from './types';

export const repairRegistrationApiSlice = createApi({
  reducerPath: 'repairRegistrationApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      fetchCompanies: builder.query<CompanyState[], number | void>({ query: () => configs?.endpoints?.companies }),
      // fetchCompaniesPipeline: builder.query<CompanyState[], number | void>({ query: () => configs?.endpoints?.companies }),
      fetchEquipmentTypes: builder.query<EquipmentState[], number | void>({ query: () => configs?.endpoints?.equipmentTypes }),
      fetchMaterials: builder.query<MaterialState[], number | void>({ query: () => configs?.endpoints?.materialSpecs }),
      fetchGrades: builder.query<GradeState[], number | void>({ query: () => configs?.endpoints?.materialGrades }),
      fetchDesignCodes: builder.query<DesignCodeState[], number | void>({ query: () => configs?.endpoints?.designCodes }),
      fetchMaterialTypes: builder.query<MaterialTypeState[], number | void>({ query: () => configs?.endpoints?.materialTypes }),
    };
  },
});

/*
export const assetDataExternalApiSlice = createApi({
  reducerPath: 'repairRegistrationExApi',
  baseQuery: rtkFetchWrapperAssetDataReg(),
  endpoints(builder) {
    return { fetchCompaniesPipeline: builder.query<CompanyState[], number | void>({ query: () => configs?.endpoints?.extNotPipelineCompaniesList }) };
  },
});
*/

export const {
  useFetchCompaniesQuery,
  useFetchEquipmentTypesQuery,
  useFetchMaterialsQuery,
  useFetchGradesQuery,
  useFetchDesignCodesQuery,
  useFetchMaterialTypesQuery,
} = repairRegistrationApiSlice;
